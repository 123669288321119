import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useToken } from "../../server/auth/use-token";
import { downloadFile } from "../../utils/download-file";
import { DialogController } from "../../utils/hooks/use-dialog";
import { useAnalytics } from "../analytics/analytics";
import { CeTextField } from "../generic/components/form-fields/ce-text-field";
import { useAccountId } from "../generic/hooks/use-account-id";
import { useGetDesignHuddleAccessToken } from "./use-design-huddle-access-token";
import { getDesignHuddleRender } from "./utils";

export const DesignHuddleExportModal: FC<{
  projectId: string;
  page: number;
  filename: string;
  dialog: DialogController<unknown>;
}> = ({ projectId, page, dialog, filename: defaultFilename }) => {
  const getToken = useToken();
  const getAccessToken = useGetDesignHuddleAccessToken();
  const { gaEvent } = useAnalytics();

  const accountId = useAccountId();
  const { t } = useTranslation();

  const [filename, setFilename] = useState(defaultFilename);
  const [format, setFormat] = useState("png");
  const [renderState, setRenderState] = useState<{
    loading: boolean;
    url?: string;
    error?: string;
  }>({ loading: false });

  const handleExport = useCallback(() => {
    void (async () => {
      if (!accountId) return;
      setRenderState({ loading: true });

      try {
        const downloadUrl = await getDesignHuddleRender({
          getToken,
          accessToken: await getAccessToken([projectId]),
          projectId,
          filename,
          format,
          pageNumber: page,
        });
        setRenderState({ loading: false, url: downloadUrl });
      } catch (e) {
        if (e instanceof TypeError) {
          setRenderState({ loading: false, error: e.message });
        }
      }
    })();
  }, [accountId, getToken, getAccessToken, projectId, filename, format, page]);

  useEffect(() => {
    if (renderState.url) {
      void downloadFile({ url: renderState.url, filename: `${filename}.${format}` });
    }
  }, [filename, format, renderState.url]);

  const handleClose = useCallback(() => {
    dialog.handleClose();
    setRenderState({ loading: false });
  }, [dialog]);

  if (renderState.error) {
    toast.error(renderState.error);
  }

  return (
    <Dialog open={dialog.open} onClose={handleClose} fullWidth>
      <DialogTitle display="flex" component={"div"}>
        <Typography flexGrow={1} variant="h5">
          {renderState.url
            ? t("Export Successful")
            : renderState.loading
              ? t("Rendering Design for Export")
              : t("Export Your Design")}
        </Typography>
        <IconButton data-analytics-id="design-huddle-export-dialog-close" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      {renderState.url ? (
        <Success filename={`${filename}.${format}`} url={renderState.url} onClose={handleClose} />
      ) : renderState.loading ? (
        <Progress onClose={handleClose} />
      ) : (
        <DialogContent>
          <Stack gap={4} mt={2}>
            <CeTextField
              label={t("Export as")}
              value={filename}
              onChange={(e) => setFilename(e.currentTarget.value)}
            />
            <Autocomplete
              value={format}
              options={["png", "pdf"]}
              onChange={(e, newValue) => setFormat(newValue!)}
              renderInput={(params) => <CeTextField {...params} label={t("Format")} />}
            />
            <DialogActions>
              <LoadingButton
                data-analytics-id="design-huddle-export-dialog-export"
                onClick={() => {
                  gaEvent("multiformat_button", {
                    action: "click",
                    value: "Export Image in Export Modal",
                  });
                  handleExport();
                }}
                loading={renderState.loading}
                variant="contained"
              >
                {t("Export")}
              </LoadingButton>
            </DialogActions>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
};

const Progress: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Stack gap={4}>
        <Typography>{t("Rendering...")}</Typography>
        <LinearProgress />
        <DialogActions>
          <Button
            data-analytics-id="design-huddle-export-dialog-cancel"
            variant="outlined"
            color="warning"
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Stack>
    </DialogContent>
  );
};

const Success: FC<{
  url: string;
  filename: string;
  onClose: () => void;
}> = ({ url, filename, onClose }) => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Stack gap={4}>
        <Typography>
          <Trans i18nKey="Download $$filename starting" filename={filename}>
            Download of {{ filename }} should start automatically
          </Trans>
        </Typography>
        <Typography variant="body2">
          <Trans i18nKey="Download">
            If your download does not start automatically,{" "}
            <Link href={url} download={filename} component={"a"} target="_blank">
              click here
            </Link>
          </Trans>
        </Typography>
        <DialogActions>
          <Button
            data-analytics-id="design-huddle-export-dialog-ok"
            onClick={onClose}
            variant="contained"
          >
            {t("OK")}
          </Button>
        </DialogActions>
      </Stack>
    </DialogContent>
  );
};

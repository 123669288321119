import { paths } from "../../../../api-engine/definitions/self-driving-definitions";

export type CreateContentRequest = {
  q: string;
  format: string;
  tone?: string;
};
export type Multiformat =
  paths["/multiformat"]["get"]["responses"]["200"]["content"]["application/json"];

export type ContentType = Multiformat["content-types"][number];

export type Layout = Multiformat["breakdown"][string][number];

export type DhTemplateConfig = {
  templateCode: string;
  dimensions: { width?: number; height?: number };
};
export type ContentTypeOption = {
  label: string;
  value: string;
  order: number | null;
};

export const isLongform = (format: string): boolean =>
  ["FAQ", "Manager One-Pager"].includes(format);

import { CircularProgress } from "@mui/material";
import { FC } from "react";
import { redirect } from "react-router-dom";
import { useFlag } from "../../server/optimizely";

const AssetLibraryPage: FC = () => {
  const [enabled, , ready] = useFlag("asset_library");
  if (ready && !enabled) redirect("/404");
  if (!ready) return <CircularProgress />;
  return <div>Asset Library</div>;
};

export default AssetLibraryPage;

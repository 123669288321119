import { CircularProgress } from "@mui/material";
import { isNil } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TemplateCustomizationObject } from "../../modules/design-huddle/brand-info-customizations/types";
import { useCreateDesignHuddleProject } from "../../modules/design-huddle/use-create-design-huddle-conent-project";
import { useDesignHuddle } from "../../modules/design-huddle/use-design-huddle";
import { EditContent } from "../../modules/edit-content/edit-content";
import { LoadingModal } from "../../modules/edit-content/loading-modal";
import { Page } from "../../modules/layout/components/page";
import { DhTemplateConfig } from "../../modules/multiformat/store/types";
import { useFlag } from "../../server/optimizely";

type LocationState = {
  customizations: TemplateCustomizationObject;
  templateConfig: DhTemplateConfig;
  page: number;
};

const CreateContentEditPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [projectId, setProjectId] = useState<string>();
  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");

  const [searchParams] = useSearchParams();
  const { customizations, templateConfig, page } = useMemo(() => {
    const d = searchParams.get("d");
    const decoded = atob(d || "");
    return JSON.parse(decoded) as LocationState;
  }, [searchParams]);

  const designHuddleReady = useDesignHuddle([]);

  const createDesignHuddleProject = useCreateDesignHuddleProject();

  useEffect(() => {
    if (!designHuddleReady || isNil(templateConfig) || isNil(customizations) || isNil(page)) return;

    void createDesignHuddleProject({ templateCode: templateConfig.templateCode, customizations })
      .then(setProjectId)
      .catch((e) => {
        console.error(e);
      });
  }, [createDesignHuddleProject, customizations, designHuddleReady, page, templateConfig]);

  const [ready, setReady] = useState(false);
  const handleReady = useCallback(() => setReady(true), []);

  useEffect(() => {
    if (multiFormatLoaded && !multiFormat) {
      navigate("/404");
    }
  }, [multiFormat, multiFormatLoaded, navigate]);

  if (!multiFormatLoaded) return <CircularProgress />;

  return (
    <Page title={t("Edit Content | ChangeEngine")} sx={{ height: "100%" }}>
      {projectId && <EditContent projectId={projectId} page={page} onReady={handleReady} />}
      {projectId !== "dummy" &&<LoadingModal open={!ready} /> }
    </Page>
  );
};

export default CreateContentEditPage;

import {
  $applyNodeReplacement,
  LexicalEditor,
  TextNode,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
} from "lexical";

export type SerializedMergeTagV2Node = SerializedTextNode & {
  tag: string;
};

const thisStyle = "background-color: rgba(24, 119, 232, 0.2)";

export class MergeTagV2Node extends TextNode {
  __tag: string;

  static getType(): string {
    return "merge-tag-v2";
  }

  static clone(node: MergeTagV2Node): MergeTagV2Node {
    return new MergeTagV2Node(node.__tag, node.__key);
  }

  static importJSON(serializedNode: SerializedMergeTagV2Node): MergeTagV2Node {
    const node = $createMergeTagV2Node(
      serializedNode.tag.trim().length === 0 ? "ERROR_NODE" : serializedNode.tag,
    );
    if (!node) throw new Error("Failed to import MergeTagV2Node");

    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(tag: string, key?: NodeKey) {
    super(tag, key);
    this.__tag = tag;
  }

  exportJSON(): SerializedMergeTagV2Node {
    return {
      ...super.exportJSON(),
      tag: this.__tag,
      type: "merge-tag-v2",
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = thisStyle;
    dom.classList.add("merge-tag-v2");
    return dom;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    if (!element) return { element };

    const getInnermostChild = (
      node: HTMLElement | DocumentFragment | Text,
    ): HTMLElement | DocumentFragment | Text | null => {
      if ("children" in node && node?.children?.length)
        return getInnermostChild(node.children[0] as HTMLElement);
      return node;
    };

    const innerMostChild = getInnermostChild(element);

    if (innerMostChild && "style" in innerMostChild) {
      // Updating innerHTML here as opposed to innerText in order to get tests working as
      // JSDOM appears to have issues with updating parents innerHTML when child innerText
      // is updated: https://github.com/jsdom/jsdom/issues/3313
      innerMostChild.innerHTML = `{{${this.__tag}}}`;
      innerMostChild.style.cssText = "";
    }
    return { element };
  }

  splitText(): TextNode[] {
    // Really hacky and used so that when formatting is partially applied to a merge tag,
    // it is actually applied to the whole tag:
    // https://github.com/facebook/lexical/blob/v0.12.0/packages/lexical/src/LexicalSelection.ts#L1386-L1389
    return [this, this];
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMergeTagV2Node(tag: string): MergeTagV2Node | null {
  if (tag.trim().length === 0) return null;

  const node = new MergeTagV2Node(tag);
  node.setMode("token").toggleDirectionless();
  return $applyNodeReplacement(node);
}

export function $isMergeTagV2Node(node?: LexicalNode | null): node is MergeTagV2Node {
  return node instanceof MergeTagV2Node;
}

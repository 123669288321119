import { Atom, atom } from "jotai";
import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { isEqual, omit } from "lodash-es";
import { Orientation } from "unsplash-js";
import { Basic as UnsplashPhotoBasic } from "unsplash-js/dist/methods/photos/types";
import { config } from "../../config";
import { UnsplashService } from "../../server/api/unsplash";
import { getTokenAtom } from "../../server/auth/store";
import { authHeader } from "../../utils/auth-header";
import { UUID, uuidv4 } from "../../utils/uuid";
import { BrandTone } from "../brand-kit/server/brand-tone-service";
import { brandingAtom, brandingImageTypeAtom } from "../brand-kit/store";
import { TemplateInfo } from "../brand-kit/types";
import { brandInfoToCustomizations } from "../design-huddle/brand-info-customizations/brand-info-customizations";
import {
  getDesignHuddleProjectRender,
  getPageAspect,
  getPageNumber,
  imageURL,
} from "../design-huddle/utils";
import {
  Channel,
  Template,
  TemplateImage,
  TemplateImageFamilyAtom,
  TemplateType,
} from "../generic/atoms/types/template";
import { huddle } from "../huddle/huddle";
import { selfDriving } from "../self-driving/self-driving";
import { ToneType } from "../self-driving/types";
import {
  ContextQuery,
  GenerateQuery,
  RecipeQuery,
  TemplateInitialContext,
  isContextQuery,
  isRecipeQuery,
} from "./types";

export const accountIdAtom = atom<UUID | null>(null);

export const gptModelAtom = atom<"ga" | "alpha" | null>("ga");

export const promptAtom = atom<string | null>(null);

export const templateQueryAtom = atom(
  (get): RecipeQuery | null => {
    const prompt = get(promptAtom);
    return prompt ? { recipeId: "default", answers: [prompt] } : null;
  },
  (get, set, update: string) => set(promptAtom, update),
);

export const chatIdAtom = atom<UUID | null>(null);

const recipeIdAtom = atom<string | null>(null);
const answersAtom = atom<string[]>([]);

export const recipeQueryAtom = atom(
  (get): RecipeQuery | null => {
    const recipeId = get(recipeIdAtom);
    const answers = get(answersAtom);
    return recipeId && answers.length > 0 ? { recipeId, answers } : null;
  },
  (get, set, update: RecipeQuery | null) => {
    if (update === null) {
      set(recipeIdAtom, null);
      set(answersAtom, []);
      return;
    }
    set(recipeIdAtom, update.recipeId);
    set(answersAtom, update.answers);
  },
);

export const contextQueryAtom = atom<ContextQuery | null>(null);

export const queryAtom = atom<GenerateQuery | null>(
  (get) => get(contextQueryAtom) ?? get(templateQueryAtom) ?? get(recipeQueryAtom),
);

const selectedRecipeAtom = atom((get) => {
  const recipeId = get(recipeIdAtom);
  if (recipeId === null) return null;

  if (recipeId === "default") {
    const defaultRecipe = get(defaultRecipeAtom);
    return defaultRecipe.state === "hasData" && defaultRecipe.data;
  }

  const recipes = get(recipesAtom);
  if (!recipes || recipes.state !== "hasData") {
    return undefined;
  }
  return recipeId && recipes.data?.find((x) => x.id === recipeId);
});

export const templateInitialContextAtom = atomWithDefault<Promise<TemplateInitialContext | null>>(
  async (get) => {
    const q = get(queryAtom);

    const token = await get(getTokenAtom).getToken();
    const gptModel = get(gptModelAtom);

    if (!token || !q || !gptModel) return null;

    if (isRecipeQuery(q)) {
      const { data, error } = await selfDriving.POST("/recipes/{recipeId}/initial_context", {
        body: {
          ...("prompt" in q && { prompt: q.prompt }),
          ...("answers" in q && { answers: q.answers.map((a) => ({ answer: a })) }),
        },
        params: {
          path: { recipeId: q.recipeId },
          query: { model: gptModel },
        },
        ...authHeader(token),
      });

      if (error || !data) throw new Error(error.error || "Request failed");

      return data;
    }

    if (isContextQuery(q)) {
      return q;
    }

    return null;
  },
);

export const debugLabel = <T>(l: string, a: T): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  a.debugLabel = l;
  return a;
};

export const templateContentFamily = atomFamily(
  ({
    contentKey,
    query,
    customTone,
  }: {
    contentKey: ToneType;
    query?: GenerateQuery | null;
    customTone?: BrandTone | null;
  }) =>
    debugLabel(
      `templateContentFamily(${query?.recipeId}-${contentKey}${customTone ? "-" + customTone.name : ""})`,
      atomWithDefault(async (get) => {
        const i = await get(templateInitialContextAtom);
        const model = get(gptModelAtom);
        const token = await get(getTokenAtom).getToken();

        if (!model || !i || !token || !query || (contentKey === "custom" && !customTone))
          return null;

        const { data, error } = await selfDriving.POST("/recipes/{recipeId}/follow_up/text", {
          body: {
            chatId: i.chatId,
            ...("answers" in query && { answers: query.answers.map((a) => ({ answer: a })) }),
            ...(contentKey === "custom"
              ? { tone: "custom", toneSummary: customTone!.summary }
              : { tone: contentKey }),
          },
          params: {
            path: { recipeId: query.recipeId },
            query: { model },
          },
          ...authHeader(token),
        });

        if (error || !data) throw new Error(error.error || "Request failed");

        return { type: "generated" as const, data };
      }),
    ),
  isEqual,
);

export const brandToneAtom = atom<BrandTone | null>(null);
export const channelsAtom = atom<Channel[]>([]);

export const templateImageFamily: TemplateImageFamilyAtom = atomFamily(
  ({ num }: { num: number }) =>
    debugLabel(
      `templateImageFamily(${num})`,
      atomWithDefault(async (get) => {
        const token = await get(getTokenAtom).getToken();
        const q = get(queryAtom);
        const brandTone = get(brandToneAtom);
        const gptModel = get(gptModelAtom);

        const i = await get(templateInitialContextAtom);

        if (!q || !token || !i || !gptModel) return null;

        const { data, error } = await selfDriving.POST(
          "/recipes/{recipeId}/follow_up/image/{imgNum}",
          {
            body: {
              chatId: i.chatId,
              ...("answers" in q && { answers: q.answers.map((a) => ({ answer: a })) }),
              ...(brandTone?.use_in_images && { customTone: { summary: brandTone.summary } }),
            },
            params: {
              path: { recipeId: q.recipeId, imgNum: `${num + 1}` },
              query: { model: gptModel },
            },
            ...authHeader(token),
          },
        );

        if (error || !data) throw new Error(error.error || "Request failed");

        return { chatId: data.chatId, response: data.response };
      }),
    ),
  isEqual,
);

const bannerTemplateNames = {
  bold: "AI Bold Banner",
  sophisticated: "AI Sophisticated Banner",
  organic: "AI Organic Banner",
  minimalist: "AI Minimalist Banner",
  formal: "AI Formal Banner",
};

const imageTemplateFamily = atomFamily((num: number) =>
  debugLabel(
    `imageTemplateFamily(${num})`,
    atom((get) => {
      const style = get(brandingAtom);
      const selectedRecipe = get(selectedRecipeAtom);
      const image_type = get(brandingImageTypeAtom);

      if (selectedRecipe) {
        const templateCode =
          image_type === "banner"
            ? bannerTemplateNames[style.branding_type]
            : selectedRecipe?.designhuddle_template_names
              ? selectedRecipe.designhuddle_template_names[style.branding_type]
              : "AI Templates";

        return {
          template_code: templateCode,
          page_number: num + 1,
        };
      }

      const templateCode =
        style.branding_type === "bold" && image_type === "square"
          ? "AI Bold Test Template"
          : "AI Templates";

      return style
        ? {
            template_code: templateCode,
            page_number: style.branding_type === "bold" ? num + 1 : getPageNumber(style),
          }
        : null;
    }),
  ),
);

export const unsplashSearchFamily = atomFamily(
  ({ query, orientation }: { query: string; orientation: Orientation }) =>
    debugLabel(
      `unsplashSearchFamily(${query}, ${orientation})`,
      atomWithDefault(async (get) => {
        const token = await get(getTokenAtom).getToken();
        return token
          ? new UnsplashService(token)
              .search(query, orientation)
              .then((res) => res.data?.photos.results)
          : undefined;
      }),
    ),
  isEqual,
);

export const imagePreviewDimensions = atom((get) => {
  const image_type = get(brandingImageTypeAtom);

  return image_type === "banner"
    ? { width: 600, height: 200, image_type: "banner" }
    : { width: 800, height: 800, image_type: "square" };
});

export const imagePreviewURLFamily = atomFamily(
  (info: TemplateInfo) =>
    debugLabel(
      `imagePreviewURLFamily(${JSON.stringify(info)})`,
      atomWithDefault(
        (get) =>
          new Promise<string>((resolve, reject) => {
            const customizations = brandInfoToCustomizations({
              brandInfoSelected: get(brandingAtom),
              templateInfo: info,
            });

            if (info.dhProject) {
              const account_id = get(accountIdAtom);
              if (account_id) {
                resolve(
                  `${imageURL(account_id, info.dhProject.project_id)}?c=${new Date().getTime()}`,
                );
              } else {
                resolve(
                  info.dhProject.pages.find((page) => page.page_number === info.page_number)
                    ?.thumbnail_url ?? "",
                );
              }
            }

            const dimensions = omit(get(imagePreviewDimensions), "image_type");

            return DSHDLib.getVariableTemplatePreviewURL(
              {
                template_code: info.template_code,
                customizations,
                page_number: info.page_number,
                ...dimensions,
              },
              (err, url) => {
                if (err) reject(err);
                resolve(url);
              },
            );
          }),
      ),
    ),
  isEqual,
);

const imageURLFamily = atomFamily(
  (info: TemplateInfo) =>
    debugLabel(
      `imageURLFamily(${JSON.stringify(info)})`,
      atomWithDefault(async (get) => {
        const customizations = brandInfoToCustomizations({
          brandInfoSelected: get(brandingAtom),
          templateInfo: info,
        });
        const dimensions = omit(get(imagePreviewDimensions), "image_type");

        const projectPages = await get(projectPagesFamily(info.template_code));
        const accessToken = await getDesignHuddleAccessToken(
          [projectPages.projectId],
          get(getTokenAtom).getToken,
        );

        const newImageId = uuidv4();
        return await getDesignHuddleProjectRender({
          getToken: get(getTokenAtom).getToken,
          accessToken,
          projectPages,
          newImageId,
          customizations,
          dimensions,
          pageNumber: info.page_number,
        });
      }),
    ),
  isEqual,
);

const projectPagesFamily = atomFamily(
  (templateCode: string) =>
    debugLabel(
      `projectPagesFamily(${templateCode})`,
      atom(async (get) => {
        const accessToken = await getDesignHuddleAccessToken([], get(getTokenAtom).getToken);
        const createProjectResponse = await fetch(
          `https://${config.design_huddle.domain}/api/projects`,
          {
            method: "POST",
            mode: "cors",
            credentials: "omit",
            headers: { authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({ template_code: templateCode }),
          },
        );

        const { success: createProjectSuccess, data: createProjectData } =
          (await createProjectResponse.json()) as {
            success: boolean;
            data: {
              project_id: string;
            };
          };

        if (!createProjectSuccess || !createProjectData) throw new Error("Project Create Error");

        const projectId = createProjectData.project_id;
        const projectResp = await fetch(
          `https://${config.design_huddle.domain}/api/projects/${projectId}`,
          {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: { authorization: `Bearer ${accessToken}` },
          },
        );

        const { success: projectSuccess, data: projectData } = (await projectResp.json()) as {
          success: boolean;
          data: {
            pages: { page_id: string; page_number: number }[];
          };
        };

        if (!projectSuccess || !projectData) throw new Error("Project Error");

        return { pages: projectData.pages, projectId };
      }),
    ),
  isEqual,
);

const generatedImageFamily = atomFamily((num: number) =>
  debugLabel(
    `generatedImageFamily(${num})`,
    atom(async (get) => {
      const q = get(queryAtom);
      const token = await get(getTokenAtom).getToken();
      const s = await get(templateImageFamily({ num: 0 }));

      if (!token || !q || !q.answers) return null;

      const { data, error } = await selfDriving.POST("/recipes/{recipeId}/image", {
        body: {
          ...("prompt" in q && { prompt: s?.response.search ?? q.prompt }),
          answers: q.answers,
        },
        params: { path: { recipeId: q.recipeId } },
        ...authHeader(token),
      });

      if (error || !data) throw new Error(error.error || "Request failed");

      return data;
    }),
  ),
);

const GENERATE_IMAGE_INDEX = 5;
const TEXT_ONLY_IMAGES = [6, 7, 8];

export interface ImagePreviewDirectProps {
  num: number;
  chatId?: UUID;
  header?: string;
  subheader?: string;
  search?: string;
  Photo?: UnsplashPhotoBasic;
}

export const imagePreviewDirectFamily = atomFamily(
  ({ num, chatId, header, subheader, search, Photo }: ImagePreviewDirectProps) =>
    debugLabel(
      `imagePreviewDirectFamily(${num}, ${chatId}, ${header}, ${subheader}, ${search})`,
      atomWithDefault(async (get) => {
        const template = get(imageTemplateFamily(num));
        if (!template) return null;

        const style = get(brandingAtom);
        const orientation = getPageAspect(style);

        const info: TemplateInfo = {
          template_code: template.template_code,
          page_number: template.page_number,
          header,
          subheader,
          unsplashExtra: undefined,
          stableDiffusionImage: false,
          ...(Photo && { Photo: Photo.urls.regular }),
        };

        if (num === GENERATE_IMAGE_INDEX) {
          const g = await get(generatedImageFamily(num));
          if (!g) return null;
          info.Photo = g.url;
          info.stableDiffusionImage = true;
        } else if (!TEXT_ONLY_IMAGES.includes(num)) {
          let img = Photo;
          if (!img) {
            const images = await get(
              unsplashSearchFamily({
                query: search ?? header ?? "",
                orientation,
              }),
            );
            img = images?.slice(0, num + 1)?.slice(-1)?.[0];
            info.Photo = img?.urls?.regular ?? "";
          }

          info.unsplashExtra = img
            ? {
                downloadUrl: img.links.download_location,
                attributionData: {
                  url: img.user.links.html,
                  name: img.user.name,
                },
              }
            : undefined;
        }

        return {
          ...info,
          url: get(loadable(imagePreviewURLFamily(info))),
          urlHighQuality: get(loadable(imageURLFamily(info))),
          chatId,
        };
      }),
    ),
  isEqual,
);

export async function getDesignHuddleAccessToken(
  projects: string[],
  getToken: () => Promise<string | null>,
): Promise<string> {
  const response = await huddle.POST("/", {
    body: { projects },
    headers: { Authorization: `Bearer ${await getToken()}` },
  });
  return response.data!.access_token;
}

export const templateImagePreviewFamily = atomFamily(
  ({ num }: { num: number }) =>
    debugLabel(
      `templateImagePreviewFamily(${num})`,
      atomWithDefault(async (get) => {
        const data = await get(templateImageFamily({ num }));
        if (!data) return null;
        return await get(imagePreviewDirectFamily({ num, chatId: data.chatId, ...data.response }));
      }),
    ),
  isEqual,
);

export const momentImageSearchFamily = atomFamily(
  (data: { num: number; title: string; textContent: string }) =>
    debugLabel(
      `momentImageSearchFamily(${data.num})`,
      atomWithDefault(
        () => null as { num: number; search: string; header: string; subheader?: string } | null,
      ),
    ),
  isEqual,
);

export const momentImageFamily = atomFamily(
  (data: { num: number; title: string; textContent: string }) =>
    debugLabel(
      `templateImagePreviewFamily(${data.num})`,
      atomWithDefault((get) => {
        const imageData = get(momentImageSearchFamily(data));
        if (!imageData) return undefined;
        return get(loadable(imagePreviewDirectFamily(imageData)));
      }),
    ),
  isEqual,
);

export type ImagePreview = NonNullable<Awaited<NonNullable<TemplateImage>>>;

export const imageAtom = atomWithDefault(() => 0);

type LoadableTemplateAtom = Atom<Loadable<Template | null>>;

export const generatedTemplateAtom: LoadableTemplateAtom = loadable(
  atom(async (get): Promise<Template | null> => {
    const i = await get(templateInitialContextAtom);
    const query = get(queryAtom);
    const customTone = get(brandToneAtom);
    const custom = loadable(
      templateContentFamily({
        contentKey: "custom",
        query,
        customTone,
      }),
    );
    custom.debugLabel = "custom";
    const professional = loadable(templateContentFamily({ contentKey: "professional", query }));
    professional.debugLabel = "professional";

    return i
      ? {
          ...i.response,
          type: TemplateType.moment,
          id: "AI generated",
          slug: "ai",
          context: i.response.what,
          program: {
            color: "#FF747F",
            name: "Other",
            slug: "other",
            photo: "",
          },
          date: null,
          recommendations: {
            send_time: "Immediately",
            channel: "#general",
          },
          geo: "International",
          end_date: null,
          trigger: null,
          segment: "" as const,
          trending: null,
          hero: false,
          top: null,
          content: {
            ...(get(brandToneAtom) && {
              custom: get(custom),
            }),
            professional: get(professional),
            casual: get(loadable(templateContentFamily({ query, contentKey: "casual" }))),
            direct: get(loadable(templateContentFamily({ query, contentKey: "direct" }))),
          },
          images: [...Array(9).keys()].map((num) =>
            get(loadable(templateImagePreviewFamily({ num }))),
          ),
          selectedImage: get(imageAtom),
          channel: null,
        }
      : null;
  }),
);

export const generatePageHeadingAtom = atom((get) => {
  const template = get(generatedTemplateAtom);
  return {
    heading:
      template.state === "hasData" && template.data
        ? template.data.title
        : "Create a Moment with AI",
    subheading:
      template.state === "loading"
        ? "Our AI is working to create your communication."
        : "Generated by ChangeEngine AI",
  };
});

const baseRecipesAtom = atom(async (get) => {
  const token = await get(getTokenAtom).getToken();
  if (!token) return null;

  const { data } = await selfDriving.GET("/recipes", {
    ...authHeader(token),
  });
  return !data ? null : data;
});

export const defaultRecipeAtom = loadable(
  atom(async (get) => {
    const recipes = await get(baseRecipesAtom);

    return recipes?.find((x) => x.name === "Default") ?? null;
  }),
);

export const recipesAtom = loadable(
  atom(async (get) => {
    const recipes = await get(baseRecipesAtom);

    return recipes?.filter((x) => x.name !== "Default") ?? null;
  }),
);

import { BrandInfo, BrandInfoSelected, TemplateInfo } from "../../brand-kit/types";
import { TemplateCustomizationObject } from "./types";

export function optionalMergeTag(
  key: string,
  text?: string,
): { [x: string]: { auto_fit: boolean; text: string } } {
  return text || text === "" ? { [key]: { auto_fit: true, text } } : {};
}

export function brandInfoToCustomizations({
  brandInfoSelected,
  templateInfo,
  brandInfo,
}: {
  brandInfoSelected?: BrandInfoSelected;
  templateInfo?: TemplateInfo;
  brandInfo?: BrandInfo | null;
}): {
  classes: TemplateCustomizationObject["classes"];
} {
  const logosUnsorted = (brandInfo?.choices?.logos ?? []).reduce(
    (acc, logo) => {
      if (logo.type === "other") return acc;
      if (acc[logo.type]) return acc;
      return {
        ...acc,
        [`P3-${logo.type}`]: { url: logo.src },
        [`P2-${logo.type}`]: { url: logo.src },
        [`P1-${logo.type}`]: { url: logo.src },
      };
    },
    brandInfoSelected?.logo
      ? {
          [["icon", "logo", "symbol"].includes(brandInfoSelected.logo.type)
            ? `P1-${brandInfoSelected.logo.type}`
            : "P1-logo"]: {
            url: brandInfoSelected.logo.src,
          },
        }
      : {},
  );

  const logos = {
    logo: logosUnsorted["P1-logo"] ?? logosUnsorted["P1-icon"] ?? logosUnsorted["P1-symbol"],
    "P3-symbol": logosUnsorted["P3-symbol"],
    "P3-icon": logosUnsorted["P3-icon"],
    "P3-logo": logosUnsorted["P3-logo"],
    "P2-symbol": logosUnsorted["P2-symbol"],
    "P2-icon": logosUnsorted["P2-icon"],
    "P2-logo": logosUnsorted["P2-logo"],
    "P1-symbol": logosUnsorted["P1-symbol"],
    "P1-icon": logosUnsorted["P1-icon"],
    "P1-logo": logosUnsorted["P1-logo"],
  };

  return {
    classes: {
      ...logos,
      ...(brandInfoSelected?.color ? { accentcolor: { color: brandInfoSelected.color.hex } } : {}),
      ...(templateInfo?.Photo ? { Photo: { masked_media: { url: templateInfo.Photo } } } : {}),
      ...optionalMergeTag("header", templateInfo?.header),
      ...optionalMergeTag("subheader", templateInfo?.subheader),
      ...optionalMergeTag("tagline", templateInfo?.tagline),
      ...optionalMergeTag("description", templateInfo?.description),
      ...optionalMergeTag("hashtag-1", templateInfo?.["hashtag-1"]),
      ...optionalMergeTag("hashtag-2", templateInfo?.["hashtag-2"]),
      ...optionalMergeTag("footer", templateInfo?.footer),
      ...optionalMergeTag("logo-program-name", templateInfo?.["logo-program-name"]),
      ...optionalMergeTag("logo-tagline", templateInfo?.["logo-tagline"]),
      ...optionalMergeTag("certificate-title", templateInfo?.["certificate-title"]),
      ...optionalMergeTag("certificate-description", templateInfo?.["certificate-description"]),
      ...optionalMergeTag("question-1", templateInfo?.["question-1"]),
      ...optionalMergeTag("answer-1", templateInfo?.["answer-1"]),
      ...optionalMergeTag("question-2", templateInfo?.["question-2"]),
      ...optionalMergeTag("answer-2", templateInfo?.["answer-2"]),
      ...optionalMergeTag("question-3", templateInfo?.["question-3"]),
      ...optionalMergeTag("answer-3", templateInfo?.["answer-3"]),
      ...optionalMergeTag("question-4", templateInfo?.["question-4"]),
      ...optionalMergeTag("answer-4", templateInfo?.["answer-4"]),
      ...optionalMergeTag("question-5", templateInfo?.["question-5"]),
      ...optionalMergeTag("answer-5", templateInfo?.["answer-5"]),
      ...optionalMergeTag("question-6", templateInfo?.["question-6"]),
      ...optionalMergeTag("answer-6", templateInfo?.["answer-6"]),
      ...optionalMergeTag("question-7", templateInfo?.["question-7"]),
      ...optionalMergeTag("answer-7", templateInfo?.["answer-7"]),
      ...optionalMergeTag("question-8", templateInfo?.["question-8"]),
      ...optionalMergeTag("answer-8", templateInfo?.["answer-8"]),
      ...optionalMergeTag("question-9", templateInfo?.["question-9"]),
      ...optionalMergeTag("answer-9", templateInfo?.["answer-9"]),
      ...optionalMergeTag("subheader-1", templateInfo?.["subheader-1"]),
      ...optionalMergeTag("subheader-2", templateInfo?.["subheader-2"]),
      ...optionalMergeTag("subheader-3", templateInfo?.["subheader-3"]),
      ...optionalMergeTag("subheader-4", templateInfo?.["subheader-4"]),
      ...optionalMergeTag("subheader-5", templateInfo?.["subheader-5"]),
      ...optionalMergeTag("subheader-6", templateInfo?.["subheader-6"]),
      ...optionalMergeTag("section-1", templateInfo?.["section-1"]),
      ...optionalMergeTag("section-2", templateInfo?.["section-2"]),
      ...optionalMergeTag("section-3", templateInfo?.["section-3"]),
      ...optionalMergeTag("section-4", templateInfo?.["section-4"]),
      ...optionalMergeTag("section-5", templateInfo?.["section-5"]),
      ...optionalMergeTag("section-6", templateInfo?.["section-6"]),
    },
  };
}

// get source project ?

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useToken } from "../../../server/auth/use-token";
import { ImagesService } from "../../../server/cloudflare/images-service";
import { uuidv4 } from "../../../utils/uuid";
import { FilePicker } from "../../generic/components/file-picker/file-picker";
import { BrandKitLogo } from "../server/brand-kit-service";

export const BrandKitFilePicker: FC<{
  open: boolean;
  handleClose: () => void;
  onChange: (logo: Pick<BrandKitLogo, "src" | "format" | "type">) => void;
}> = ({ open, handleClose, onChange }) => {
  const [currentValue, setCurrentValue] = useState<Pick<BrandKitLogo, "src" | "format" | "type">>({
    src: "",
    format: "",
    type: "logo",
  });

  const { t } = useTranslation();
  const getToken = useToken();

  const handleUpload = useCallback(
    async (file: File) => {
      const name = uuidv4();

      const imagesService = new ImagesService();
      const result = await imagesService.upload(file, name, await getToken());

      if (!result.success || !result.url) {
        toast.error(t("Something went wrong, please try again"));
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, format] = file.type.split("/");

      setCurrentValue((prev) => ({ ...prev, src: result.url, format }));
      return file;
    },
    [getToken, t, setCurrentValue],
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{t("Upload logo")}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FilePicker
              value={currentValue?.src ?? ""}
              handleUpload={handleUpload}
              accept={{ "image/*": [".png", ".jpeg", ".gif", ".jpg", ".svg"] }}
              text={{
                add: t("Add Image"),
                remove: t("Remove Image"),
                uploadError: t("Only one image can be uploaded at a time"),
              }}
              handleRemove={useCallback(
                () =>
                  setCurrentValue({
                    src: "",
                    format: "",
                    type: "logo",
                  }),
                [],
              )}
            />
            <FormControl fullWidth>
              <Select
                value={currentValue.type}
                onChange={(e) => {
                  setCurrentValue((prev) => ({ ...prev, type: e.target.value }));
                }}
              >
                <MenuItem value="logo">{t("Logo")}</MenuItem>
                <MenuItem value="icon">{t("Icon")}</MenuItem>
                <MenuItem value="symbol">{t("Symbol")}</MenuItem>
                <MenuItem value="other">{t("Other")}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={1}>
            <Button
              data-analytics-id="brand-kit-logo-picker-cancel"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              data-analytics-id="brand-kit-logo-picker-ok"
              variant="contained"
              onClick={() => {
                if (currentValue.src) {
                  onChange(currentValue);
                  handleClose();
                  setCurrentValue({
                    src: "",
                    format: "",
                    type: "logo",
                  });
                }
              }}
            >
              {t("OK")}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

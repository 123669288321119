import { atom } from "jotai";
import { Template } from "../../design-huddle/types";

export type AugmentedTemplate = {
  id: string;
  type: string;
  name: string;
  template: Template;
};

export const customTemplatesAtom = atom<{ [key: string]: AugmentedTemplate[] }>({});

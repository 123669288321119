import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { t } from "i18next";
import { FC, useCallback, useState } from "react";
import { DialogController } from "../../utils/hooks/use-dialog";
import { CeTextField } from "../generic/components/form-fields/ce-text-field";

export const RenameFileModal: FC<{
  filename: string;
  onSavePressed: (pickedFilename: string) => void;
  dialog: DialogController<unknown>;
}> = ({ dialog, onSavePressed, filename }) => {
  const [editableFilename, setEditableFilename] = useState(filename);

  const handleClose = useCallback(() => {
    dialog.handleClose();
  }, [dialog]);

  const handleSaveButtonPressed = useCallback(() => {
    onSavePressed(editableFilename);
    handleClose();
  }, [editableFilename, handleClose, onSavePressed]);

  return (
    <Dialog open={dialog.open} fullWidth onClose={handleClose}>
      <DialogTitle display="flex" component={"div"} sx={{ mt: 4 }}>
        <Typography flexGrow={1} variant="h6">
          {t("Name your design")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4} my={2}>
          <CeTextField
            variant="outlined"
            name="fileNameInput"
            value={editableFilename}
            onChange={(e) => setEditableFilename(e.currentTarget.value)}
            label={t("Name")}
          />
          <Stack direction="row" gap={1} justifyContent="flex-end">
            <Button
              data-analytics-id="design-huddle-rename-file-dialog-cancel"
              onClick={handleClose}
              variant="outlined"
            >
              {t("Cancel")}
            </Button>
            <Button
              data-analytics-id="design-huddle-rename-file-dialog-save"
              onClick={handleSaveButtonPressed}
              variant="contained"
            >
              {t("Save")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

import {
  Business,
  CellTower,
  DataObject,
  Folder,
  Groups,
  HelpOutline,
  Logout,
  PersonAdd,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  SvgIcon,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { first, isArray } from "lodash-es";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { usePermissions } from "../../../../server/auth/use-permissions";
import { useSupabase } from "../../../../server/supabase/hooks";
import { Menu as MenuIcon } from "../../../../styles/icons/menu";
import { usePopover } from "../../../../utils/hooks/use-popover";
import { SupabaseBrandKitService } from "../../../brand-kit/server/brand-kit-service";
import { ThemeToggle } from "../../../generic/components/settings/theme-toggle";
import { Login } from "../../../pipe/types";
import { layoutAtom } from "../store";
import { AccountSwitcher } from "./account-switcher";
import { ThemeAwareDivider } from "./divider";

interface SettingsMenuProps {
  size?: "small" | "medium" | "large";
  logins?: { [k: string]: Login };
}

export const SettingsMenu: FC<SettingsMenuProps> = ({ size = "medium", logins = {} }) => {
  const popover = usePopover<HTMLButtonElement>();

  const layout = useAtomValue(layoutAtom);

  return (
    <>
      <IconButton
        data-analytics-id="settings-menu"
        ref={popover.anchorRef}
        onClick={popover.handleOpen}
        size={size}
        data-testid="settings-button"
      >
        <SvgIcon data-testid="settings-menu-chevron" fontSize={size}>
          {layout === "sequence" ? <Settings /> : <MenuIcon />}
        </SvgIcon>
      </IconButton>
      <SettingsPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        logins={logins}
      />
    </>
  );
};

export const SettingsPopover: FC<
  {
    anchorEl: null | Element;
    onClose: () => void;
    open: boolean;
  } & Pick<SettingsMenuProps, "logins">
> = ({ anchorEl, onClose, open, logins }) => {
  const { t } = useTranslation();
  const { super_admin: is_super_admin } = usePermissions();

  const [logo, setLogo] = useState<{ src: string } | undefined>();

  useSupabase(async ({ supabase, account_id }) => {
    const { data: logoData } = await new SupabaseBrandKitService(supabase).getDefaultLogo(
      account_id,
    );

    setLogo(
      isArray(logoData?.brand_logo) ? first(logoData?.brand_logo ?? []) : logoData?.brand_logo,
    );
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      disableScrollLock
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: "250px" } }}
    >
      <MenuList>
        {is_super_admin && (
          <MenuItem component={Link} to="/manage/brand">
            <ListItemIcon>
              {logo ? (
                <Box
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                >
                  <img
                    alt="logo"
                    src={logo.src}
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </Box>
              ) : (
                <Business />
              )}
            </ListItemIcon>
            <ListItemText>{t("Your Brand")}</ListItemText>
          </MenuItem>
        )}
        {is_super_admin && open && (
          <>
            <MenuItem component={Link} to="/manage/employees" onClick={onClose}>
              <ListItemIcon>
                <Groups />
              </ListItemIcon>
              <ListItemText>{t("Employees")}</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/manage/channels" onClick={onClose}>
              <ListItemIcon>
                <CellTower />
              </ListItemIcon>
              <ListItemText>{t("Channels")}</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/manage/programs" onClick={onClose}>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText>{t("Programs")}</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/manage/segments/standard" onClick={onClose}>
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText>{t("Segments")}</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/manage/employee_data" onClick={onClose}>
              <ListItemIcon>
                <DataObject />
              </ListItemIcon>
              <ListItemText>{t("Events & Merge Tags")}</ListItemText>
            </MenuItem>
          </>
        )}
        <MenuItem
          component={Link}
          to="https://help.changeengine.com"
          target="_blank"
          rel="noreferrer"
          onClick={onClose}
        >
          <ListItemIcon>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText>{t("Help & Support")}</ListItemText>
        </MenuItem>
        <MenuItem sx={{ cursor: "default" }}>
          <ThemeToggle />
        </MenuItem>
        <ThemeAwareDivider />

        <AccountSwitcher logins={logins} />

        <MenuItem component={Link} to="/logout" onClick={onClose}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>{t("Logout")}</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { toSlug } from "../../../utils/create-slug";
import { dhTemplateConfigFamily } from "../../content-previews/content-previews";
import { debugLabel } from "../../generate/store";
import { customTemplatesAtom } from "./custom-templates-atom";
import { multiformatAtom } from "./multiformat-atom";
import { previewFamily } from "./preview-family";
import { previewUrlFamily } from "./preview-url-family";
import { Layout } from "./types";

type TemplatePreview = {
  preview: Loadable<
    Promise<{
      width?: number;
      height?: number;
      template_code: string;
      customizations: {
        [key: string]: unknown;
      };
      page_number: number;
      title?: string;
    } | null>
  >;
  url: Loadable<Promise<string | null>>;
};

const unsplashResult = (layout: Layout | undefined): number =>
  layout && layout["Unsplash result"] ? layout["Unsplash result"] - 1 : 0;
const orientation = (layout: Layout | undefined): Orientation => {
  return layout &&
    layout.Orientation &&
    ["landscape", "portrait", "squarish"].includes(layout.Orientation)
    ? (layout.Orientation as Orientation)
    : "landscape";
};

export const previewsFamily = atomFamily(
  ({
    contentType,
    answers,
  }: {
    contentType: string;
    answers: { answer: string }[];
  }) =>
    debugLabel(
      `previewsFamily(${contentType}, ${answers[0].answer})`,
      atomWithDefault((get) => {
        const templateConfig = get(dhTemplateConfigFamily(contentType));
        const layouts = get(multiformatAtom)?.breakdown[contentType];
        const formatId = toSlug(contentType).replace(/-/g, "_");

        const customTemplates = get(customTemplatesAtom);

        if (!templateConfig || !layouts) return;

        let customLayouts: TemplatePreview[] = [];
        if (
          customTemplates &&
          customTemplates[contentType] &&
          customTemplates[contentType].length > 0
        ) {
          const customTemplate = customTemplates[contentType][0];

          customLayouts = layouts.map((layout) => ({
            preview: get(
              loadable(
                previewFamily({
                  formatId,
                  answers,
                  imgNum: layout["DH Image Number"]?.toString(),
                  templateConfig: {
                    ...templateConfig,
                    templateCode: customTemplate.template.template_code ?? "",
                  },
                  unsplashResult: unsplashResult(layout),
                  orientation: orientation(layout),
                }),
              ),
            ),
            url: get(
              loadable(
                previewUrlFamily({
                  formatId,
                  answers,
                  imgNum: layout["DH Image Number"]?.toString(),
                  templateConfig: {
                    ...templateConfig,
                    templateCode: customTemplate.template.template_code ?? "",
                  },
                  unsplashResult: unsplashResult(layout),
                  orientation: orientation(layout),
                }),
              ),
            ),
          }));
        }

        return [
          ...customLayouts,
          ...layouts.map((layout) => ({
            preview: get(
              loadable(
                previewFamily({
                  formatId,
                  answers,
                  imgNum: layout["DH Image Number"]?.toString(),
                  templateConfig,
                  unsplashResult: unsplashResult(layout),
                  orientation: orientation(layout),
                }),
              ),
            ),
            url: get(
              loadable(
                previewUrlFamily({
                  formatId,
                  answers,
                  imgNum: layout["DH Image Number"]?.toString(),
                  templateConfig,
                  unsplashResult: unsplashResult(layout),
                  orientation: orientation(layout),
                }),
              ),
            ),
          })),
        ];
      }),
    ),
  isEqual,
);

import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Popover, SxProps, Theme, Typography } from "@mui/material";
import { FC, ReactNode, useRef } from "react";
import { usePopover } from "../../../utils/hooks/use-popover";
import { uuidv4 } from "../../../utils/uuid";

export const CeHelpButton: FC<{ content: ReactNode; buttonSx?: SxProps<Theme> }> = ({
  content,
  buttonSx,
}) => {
  const popover = usePopover<HTMLButtonElement>();
  const ref = useRef(uuidv4());

  return (
    <>
      <IconButton
        data-analytics-id="ce-help-button"
        onMouseEnter={popover.handleOpen}
        onMouseLeave={popover.handleClose}
        ref={popover.anchorRef}
        sx={buttonSx}
        aria-owns={ref.current}
        aria-haspopup="true"
      >
        <InfoOutlined fontSize="small" color="primary" />
      </IconButton>
      <Popover
        id={ref.current}
        open={popover.open}
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        slotProps={{
          paper: {
            onMouseEnter: popover.handleOpen,
            onMouseLeave: popover.handleClose,
            sx: { pointerEvents: "auto", maxHeight: "75vh", overflow: "scroll" },
          },
        }}
        sx={{
          pointerEvents: "none",
        }}
      >
        {typeof content === "string" ? (
          <Typography variant="body2" sx={{ p: 2, maxWidth: "30em" }}>
            {content}
          </Typography>
        ) : (
          content
        )}
      </Popover>
    </>
  );
};

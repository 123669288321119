import { useSetAtom } from "jotai";
import { useState } from "react";
import { useSupabaseCallback, useSupabaseLoader } from "../../../server/supabase/hooks";
import { notEmpty } from "../../../utils/not-empty";
import {
  BrandKitStyle,
  ContentTone,
  SetDefaultsData,
  SupabaseBrandKitService,
  SupabaseSuperAdminBrandKitService,
} from "../server/brand-kit-service";
import { brandInfoAtom, defaultBrandingAtom } from "../store";
import { BrandInfo, BrandInfoSelected } from "../types";
import { sortLogos } from "../utils";

export type UseBrandKitManager = ReturnType<
  typeof useSupabaseLoader<{ brandKit?: BrandInfo; defaults?: BrandInfoSelected }>
> & {
  save: (
    BrandInfo: BrandInfo,
    newDefaults?: { column: "primary_color_id" | "logo_id"; value: string }[],
  ) => Promise<void>;
  reset: () => Promise<void>;
  setDefault: (data: SetDefaultsData[]) => Promise<void>;
};

export const useBrandKitManager = (): UseBrandKitManager => {
  const [saving, setSaving] = useState(false);
  const setDefaultBranding = useSetAtom(defaultBrandingAtom);
  const setBrandInfo = useSetAtom(brandInfoAtom);

  const loader = useSupabaseLoader(
    async ({ supabase }) => {
      const { data } = await new SupabaseBrandKitService(supabase).maybeGet();

      if (!data) return { brandKit: undefined, defaults: undefined };

      const logos = sortLogos(data.logos ?? []);
      const colors = data.colors ?? [];

      const values = {
        name: data.name,
        domain: data.domain,
        choices: { logos, colors },
      };

      setBrandInfo(values);

      const defaultValues = {
        branding_type: data.default_branding_type
          ? (data.default_branding_type as unknown as BrandKitStyle)
          : "bold",
        default_tone: data.default_tone
          ? (data.default_tone as unknown as ContentTone)
          : "professional",
        logo: logos.find((logo) => logo.is_default_logo),
        color: colors.find((color) => color.is_default_primary_color),
      };

      setDefaultBranding(defaultValues);

      return { brandKit: values, defaults: defaultValues };
    },
    [setBrandInfo, setDefaultBranding],
  );

  const { reload, loading } = loader;

  const setDefault = useSupabaseCallback<[SetDefaultsData[]], void>(
    async ({ supabase, account_id }, data) => {
      setSaving(true);

      const { error } = await new SupabaseBrandKitService(supabase).setDefault(account_id, data);

      setSaving(false);

      if (error) throw new Error("Error setting default");

      reload();
    },
    [reload],
  );

  const save = useSupabaseCallback<
    [BrandInfo, { column: "primary_color_id" | "logo_id"; value: string }[] | undefined],
    void,
    "super_admin"
  >(
    async (
      { supabase },
      updatedBrandKit: BrandInfo,
      newDefaults?: { column: "primary_color_id" | "logo_id"; value: string }[],
    ) => {
      setSaving(true);

      const { data, error } = await new SupabaseSuperAdminBrandKitService(supabase).upsert({
        name: updatedBrandKit.name,
        domain: updatedBrandKit.domain,
        logos: updatedBrandKit.choices.logos,
        colors: updatedBrandKit.choices.colors,
      });

      if (!data || error) {
        setSaving(false);
        throw new Error("Error saving brand kit");
      }

      if (newDefaults) {
        await setDefault(
          newDefaults
            .map((x) => {
              if (x.column === "logo_id") {
                const id = updatedBrandKit?.choices.logos.find((y) => y.src === x.value)?.id;
                return { ...x, value: id };
              }
              if (x.column === "primary_color_id") {
                const id = updatedBrandKit?.choices.colors.find((y) => y.hex === x.value)?.id;
                return { ...x, value: id };
              }
            })
            .filter(notEmpty),
        );
      }

      setSaving(false);
      reload();
    },
    [reload, setDefault],
    "super_admin",
  );

  const reset = useSupabaseCallback<[], void, "super_admin">(
    async ({ supabase }) => {
      setSaving(true);

      const { error } = await new SupabaseSuperAdminBrandKitService(supabase).reset();

      setSaving(false);
      if (error) throw new Error("Error resetting brand kit");

      reload();
    },
    [reload],
    "super_admin",
  );

  const actions = { save, reset, setDefault };

  if (loading || saving) return { ...actions, loading: true, data: null, error: null, reload };

  return { ...actions, ...loader };
};

import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { atom, useAtomValue } from "jotai";
import { atomFamily } from "jotai/utils";
import { isEqual } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../analytics/analytics";
import { multiformatAtom } from "../multiformat/store/multiformat-atom";
import { previewsFamily } from "../multiformat/store/previews-family";
import { ContentPreviewImage } from "./content-preview-image";

const LARGE_PREVIEW_CONTENT_TYPES = [
  "Certificate",
  "Poster",
  "Logo Landscape",
  "Logo Square",
  "TV Display",
  "Zoom Background",
  "FAQ",
  "Manager One-Pager",
];

export const contentTypeFamily = atomFamily((contentTypeName: string) =>
  atom((get) =>
    get(multiformatAtom)?.["content-types"]?.find((c) => c["Content Type"] === contentTypeName),
  ),
);

export const dhTemplateConfigFamily = atomFamily(
  (contentTypeName?: string | null) =>
    atom((get) => {
      if (!contentTypeName) return;
      const contentType = get(contentTypeFamily(contentTypeName));
      return {
        templateCode: contentType?.["AI File DesignHuddle ID"] ?? "AI Organic Test",
        dimensions: {
          width: contentType?.["DH Dimensions Width"] ?? undefined,
          height: contentType?.["DH Dimensions Height"] ?? undefined,
        },
      };
    }),
  isEqual,
);

export const ContentPreviews: FC<{
  contentTypeName: string;
  answers: { answer: string }[];
}> = ({ contentTypeName, answers }) => {
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();
  const { t } = useTranslation();

  const contentType = useAtomValue(contentTypeFamily(contentTypeName));
  const contentTypeAppName = contentType?.["CE App Name"];
  const templateConfig = useAtomValue(dhTemplateConfigFamily(contentTypeName));

  const previews = useAtomValue(
    previewsFamily({
      contentType: contentTypeName,
      answers,
    }),
  );

  const ratio = useMemo(() => {
    if (templateConfig?.dimensions?.width && templateConfig?.dimensions?.height) {
      return templateConfig.dimensions.height / templateConfig.dimensions.width;
    }
    return 0;
  }, [templateConfig]);

  const [numItems, setNumItems] = useState(4);
  const itemsPerPage = 4;
  const numPreviews = previews?.length ?? 0;

  useEffect(() => {
    if (numPreviews > 0) {
      setNumItems(Math.min(numPreviews, itemsPerPage));
    }
  }, [answers, numPreviews]);

  const loadMore = useRef<HTMLButtonElement>(null);

  const handleLoadMore = useCallback(() => {
    gaEvent("multiformat_button", { action: "click", value: "Load More" });

    setNumItems((prev) => Math.min(prev + itemsPerPage, numPreviews));
    setTimeout(() => {
      loadMore.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }, [gaEvent, numPreviews]);

  const handleEdit = useCallback(
    (i: number) => {
      if (!contentTypeAppName) {
        return;
      }

      gaEvent("multiformat_button", {
        action: "click",
        value: `Edit Position ${i + 1}`,
      });

      const preview = previews?.[i].preview;

      if (preview?.state === "hasData" && preview.data) {
        const d = btoa(
          JSON.stringify({
            customizations: preview.data.customizations,
            templateConfig,
            page: preview.data.page_number,
          }),
        );

        const params = new URLSearchParams({
          d,
          title:
            preview.data.title && preview.data.title !== ""
              ? preview.data.title
              : contentTypeAppName,
        });
        navigate({ pathname: "/create-content/edit", search: params.toString() });
      }
    },
    [contentTypeAppName, gaEvent, previews, templateConfig, navigate],
  );

  return (
    <>
      <Box
        sx={[
          (theme) => ({
            display: "grid",
            gap: 2,
            [theme.breakpoints.down("xs")]: {
              gridTemplateColumns: "1fr",
            },
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: LARGE_PREVIEW_CONTENT_TYPES.includes(contentTypeName)
                ? "repeat(2, 1fr)"
                : "repeat(4, 1fr)",
            },
          }),
        ]}
      >
        {previews
          ?.filter((p) => p.preview.state !== "hasError" && p.url.state !== "hasError")
          .map((preview, i) => (
            <ContentPreviewImage
              key={i}
              image={preview.url}
              onEdit={() => handleEdit(i)}
              ratio={ratio}
              hidden={i >= numItems}
            />
          ))}
      </Box>
      {numItems < numPreviews && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <LoadingButton
            data-analytics-id="design-huddle-content-previews-load-more"
            onClick={handleLoadMore}
            ref={loadMore}
            variant="contained"
            color="primary"
            size="large"
            loading={false}
          >
            {t("Load More")}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

import { Edit, Restore } from "@mui/icons-material";
import { Button, Divider, IconButton, Stack, StackProps, Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import { isEqual } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { usePermissions } from "../../../server/auth/use-permissions";
import { useAnalytics } from "../../analytics/analytics";
import { ImageTypeSelect } from "../../brand-kit/components/image-type-select";
import { VisualStyleSelect } from "../../brand-kit/components/visual-select";
import { BrandKitColor, BrandKitLogo } from "../../brand-kit/server/brand-kit-service";
import { brandingAtom } from "../../brand-kit/store";
import { BrandInfoSelected, BrandKitManager } from "../../brand-kit/types";
import { BrandColorSelect } from "./brand-color-select";
import { BrandLogoSelect } from "./brand-logo-select";

export interface CompactBrandingBarProps {
  manager: BrandKitManager;
  branding: unknown;
  defaultBranding?: BrandInfoSelected;
  brandKit?: {
    choices: {
      colors: BrandKitColor[];
      logos: BrandKitLogo[];
    };
  };
  visualStyleSelector: boolean;
  imageTypeSelector: boolean;
  hasBranding: boolean;
}

export const CompactBrandingBar: FC<CompactBrandingBarProps & StackProps> = ({
  defaultBranding,
  brandKit,
  manager,
  hasBranding,
  visualStyleSelector,
  imageTypeSelector,
  ...props
}) => {
  const [selectedBranding, setSelectedBranding] = useAtom(brandingAtom);
  const { gaEvent } = useAnalytics();
  const { t } = useTranslation();
  const { super_admin } = usePermissions();
  const showEdit = hasBranding && super_admin;
  const showReset = !isEqual(selectedBranding, defaultBranding);

  return hasBranding ? (
    <Stack direction="row" gap={1} {...props}>
      {visualStyleSelector && (
        <VisualStyleSelect
          brandingStyle={selectedBranding.branding_type}
          onChange={manager.style.setSelected}
          label={null}
          sx={{ minWidth: "200px" }}
        />
      )}
      {imageTypeSelector && (
        <ImageTypeSelect
          onChange={manager.imageType.setSelected}
          label={null}
          sx={{ minWidth: "200px" }}
        />
      )}
      <Stack direction="column" gap={0.5}>
        {showEdit && (
          <Tooltip title={t("Edit Brand")} placement="right" arrow>
            <Link to="/manage/brand/brand_kit">
              <IconButton data-analytics-id="branding-bar-edit" size="small">
                <Edit fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
        )}

        {showReset && (
          <Tooltip title={t("Restore default")} placement="right" arrow>
            <IconButton
              data-analytics-id="branding-bar-compact-reset"
              onClick={() => {
                setSelectedBranding({
                  logo: defaultBranding?.logo ?? undefined,
                  color: defaultBranding?.color ?? undefined,
                  branding_type: defaultBranding?.branding_type ?? "bold",
                  default_tone: defaultBranding?.default_tone ?? "professional",
                });
                gaEvent("discover_branding", { action: "reset" });
              }}
              size="small"
            >
              <Restore fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {(showReset || showEdit) && <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />}
      <BrandColorSelect
        selected={selectedBranding.color}
        colors={brandKit?.choices.colors ?? []}
        manager={manager}
      />
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <BrandLogoSelect
        selected={selectedBranding.logo}
        logos={brandKit?.choices.logos ?? []}
        manager={manager}
        defaultBranding={defaultBranding}
      />
    </Stack>
  ) : (
    <Link to="/manage/brand/brand_kit">
      <Button data-analytics-id="branding-bar-link-add" sx={{ my: 2 }}>
        {t("Add Branding")}
      </Button>
    </Link>
  );
};

import { DeleteOutline, RestoreFromTrash } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FC, ReactElement, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useApiServiceCallback } from "../../../server/api/client";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { toISO8601 } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import { useAnalytics } from "../../analytics/analytics";
import { MomentService } from "../../moment/client";
import { SupabaseMomentService } from "../../moment/server/supabase-moment-service";

interface Props {
  id: UUID;
  isDeleted: boolean;
  isArchived: boolean;
  isActive: boolean;
  unsavedChanges: boolean;
  status: "draft" | "paused" | "finished" | "active" | "archived" | null;
  onClicked?: () => void;
}
export const CardArchiveButton = ({
  id,
  isActive,
  isArchived,
  isDeleted,
  unsavedChanges,
  onClicked,
  status,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const archiveTooltip = isActive
    ? t("Archive is unavailable for active Moments. Please pause the Moment first.")
    : unsavedChanges
      ? t("Save your changes to archive this Moment.")
      : t("Archive Moment");

  return (
    <>
      {isDeleted ? (
        <Tooltip title={t("Unarchive")} placement="top" arrow>
          <IconButton data-analytics-id="moment-card-unarchive" onClick={() => setOpenDialog(true)}>
            <RestoreFromTrash />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={<Box sx={{ textAlign: "center" }}>{archiveTooltip}</Box>}
          placement="top"
          arrow
        >
          <span>
            <IconButton
              data-analytics-id="moment-card-archive"
              onClick={() => setOpenDialog(true)}
              disabled={isActive || unsavedChanges}
            >
              <DeleteOutline />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <ArchiveDialog
        id={id}
        status={status}
        open={openDialog}
        setOpen={setOpenDialog}
        moment_archived={isArchived}
        onClicked={onClicked}
      />
    </>
  );
};

const ArchiveDialog: FC<{
  id: UUID;
  open: boolean;
  setOpen: (open: boolean) => void;
  moment_archived: boolean;
  onClicked?: () => void;
  status: "draft" | "paused" | "finished" | "active" | "archived" | null;
}> = ({ open, setOpen, moment_archived, id, onClicked, status }) => {
  const { t } = useTranslation();

  const [archiving, setArchiving] = useState(false);
  const { gaEvent } = useAnalytics();

  const pauseCallback = useApiServiceCallback(
    MomentService,
    async ({ apiService }): Promise<void> => {
      if (status !== "finished") {
        return;
      }

      const response = await apiService.setActiveStatus(id, false, false);
      if (response.error) {
        toast.error(t("Something went wrong"));
      }

      gaEvent("pause_moment", { moment_id: id, event_category: "moment" });
    },
    [id, gaEvent],
  );

  const archiveCallback = useSupabaseCallback(
    async ({ supabase }, archive: boolean) => {
      setArchiving(true);

      const new_deleted_at = archive ? toISO8601(new Date()) : null;
      const { data, error } = await new SupabaseMomentService(supabase).update(id, {
        deleted_at: new_deleted_at,
      });

      if (!data || error) {
        toast.error(t("Something went wrong"));
        setArchiving(false);
        return;
      }

      toast.success(t(archive ? "Moment successfully archived" : "Moment successfully unarchived"));
      setArchiving(false);
      if (archive) {
        gaEvent("archive_moment", { moment_id: id, event_category: "moment" });
      } else {
        gaEvent("unarchive_moment", { moment_id: id, event_category: "moment" });
      }
      onClicked?.();
    },
    [id, onClicked, t, gaEvent],
  );

  const combinedCallback = useCallback(
    async (value: boolean): Promise<void> => {
      await pauseCallback();
      await archiveCallback(value);
    },
    [pauseCallback, archiveCallback],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {t(moment_archived ? "Unarchive Moment" : "Archive Moment")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            moment_archived
              ? "Are you sure you want to unarchive this moment?"
              : "Are you sure you want to archive this moment?",
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-analytics-id={`moment-card-${moment_archived ? "unarchive" : "archive"}-dialog-cancel`}
          onClick={() => {
            setOpen(false);
          }}
          disabled={archiving}
        >
          {t("Cancel")}
        </Button>
        <LoadingButton
          data-analytics-id={`moment-card-${moment_archived ? "unarchive" : "archive"}-dialog-confirm`}
          loading={archiving}
          variant="contained"
          onClick={() => {
            setOpen(false);
            void combinedCallback(!moment_archived).catch(console.error);
          }}
        >
          {t("Yes, I'm sure")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import ThemeProvider from "../../styles/theme/theme-provider";

export const PromptSummary: FC<{ onClick: () => void }> = ({ onClick }) => {
  const [searchParams] = useSearchParams();

  const answer = searchParams.get("answer");
  const format = searchParams.get("format");

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <ThemeProvider mode="dark">
      <Box
        className="PromptSummary-root"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          py: 1,
          px: 5,
          bgcolor: "neutral.900",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            pr: 3,
          },
        }}
      >
        <Typography variant="body1" color="white" noWrap flexShrink={0} data-testid="prompt-format">
          {format}
        </Typography>
        <Typography variant="body1" color="white">
          •
        </Typography>
        <Typography
          variant="body1"
          flexGrow={1}
          textOverflow="ellipsis"
          color="white"
          noWrap
          data-testid="prompt-answer"
        >
          {answer}
        </Typography>

        {sm && (
          <Button
            data-analytics-id="create-content-edit-prompt"
            size="small"
            variant="outlined"
            onClick={onClick}
            sx={{ textWrap: "nowrap" }}
          >
            {t("Edit Prompt")}
          </Button>
        )}
        {xs && (
          <Tooltip title={t("Edit Prompt")}>
            <IconButton
              data-analytics-id="create-content-edit-prompt"
              size="small"
              onClick={onClick}
              color="primary"
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </ThemeProvider>
  );
};

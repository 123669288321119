import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { useSetAtom } from "jotai";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateContentCard } from "../../modules/create-content-card/create-content-card";
import { useGetDesignHuddleAccessToken } from "../../modules/design-huddle/use-design-huddle-access-token";
import { SupabaseDesignHuddleCustomBrandService } from "../../modules/generic/server/supabase-account-service";
import { Page } from "../../modules/layout/components/page";
import { useMultiformat } from "../../modules/multiformat/hooks/use-multi-format";
import {
  AugmentedTemplate,
  customTemplatesAtom,
} from "../../modules/multiformat/store/custom-templates-atom";
import { useFlag } from "../../server/optimizely";
import { useSupabase } from "../../server/supabase/hooks";
import { CreateFromScratchCard } from "../moments/cards/create-from-scratch-card";
import { DiscoverCard } from "../moments/cards/discover-card";
import { NewsletterCard } from "../moments/cards/newsletter-card";

const CreateContentPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");

  useEffect(() => {
    if (multiFormatLoaded && !multiFormat) navigate("/create-moment");
  }, [multiFormat, multiFormatLoaded, navigate]);

  const { error } = useMultiformat();

  const getAccessToken = useGetDesignHuddleAccessToken();

  const setCustomTemplates = useSetAtom(customTemplatesAtom);
  useSupabase(
    async ({ supabase }) => {
      const customBrandService = new SupabaseDesignHuddleCustomBrandService(supabase);
      const { data: customBrandData, error: customBrandError } =
        await customBrandService.maybeGet();

      if (!customBrandData || customBrandError) return;

      if (customBrandData.brand_id) {
        DSHDLib.configure({
          access_token: await getAccessToken([]),
          visitor: false,
        });

        const customBrandTemplates = await getCustomBrandTemplates(customBrandData.brand_id);
        setCustomTemplates(customBrandTemplates);
      }
    },
    [getAccessToken, setCustomTemplates],
  );

  if (!multiFormatLoaded) return <CircularProgress />;

  return (
    <Page title={t("Create Content | ChangeEngine")} sx={{ maxWidth: "xl" }}>
      {!error && <CreateContentCard />}
      {error && (
        <Card>
          <CardContent>
            <Box display="flex" gap={2} alignItems="center" p={2}>
              <ErrorOutlineOutlined />
              <Typography>
                {t(
                  "There was a problem trying to load our AI content generator. Please refresh your browser to try again, or use one of the options below.",
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
      <Box
        gap={4}
        m={5}
        display="grid"
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        })}
      >
        <CreateFromScratchCard />
        <DiscoverCard />
        <NewsletterCard />
      </Box>
    </Page>
  );
};

async function getCustomBrandTemplates(brand_id: number): Promise<{
  [key: string]: AugmentedTemplate[];
}> {
  return new Promise((resolve, reject) => {
    DSHDLib.getTemplates({ brand_id, search: "prod_multiformat_" }, (err, data) => {
      if (err) return reject(err);

      const templates = data.items
        .map((template) => {
          const [env, type, format, name] = template.template_title.split("_", 4);

          return {
            env,
            type,
            format,
            name,
            template,
            id: `custom-brand-template-${template.template_id}`,
          };
        })
        .filter(({ env }) => env === "prod")
        .sort((a, b) => a.name.localeCompare(b.name));

      resolve(
        templates.reduce(
          (acc, { format, ...rest }) => {
            if (!acc[format]) acc[format] = [];
            acc[format].push(rest);
            return acc;
          },
          {} as { [key: string]: AugmentedTemplate[] },
        ),
      );
    });
  });
}

export default CreateContentPage;
